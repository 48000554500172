import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import Fallback from 'components/Fallback';
import '@viva-finance/viva-components/dist/tailwind.css';
import '@viva-finance/viva-components/dist/public/index.css';

const App = lazy(() => import('App'));

// NOTE: This temporarily removed the CJ error and Default Props error. We need to go back and actually fix this.
const { error } = console;
console.error = (...args) => {
  if (/defaultProps/.test(args[0]) || /cj.viva-finance.com/.test(args[0])) return;
  error(...args);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<Fallback />}>
    <App />
  </Suspense>
);
